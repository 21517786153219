import React from "react"
import {
  StepBox,
  StepBoxArrow,
  StepBoxContent,
  StepBoxListCtn,
  StepBoxNumberCtn,
  StepBoxTitle
} from "./StyledRoundedCardWithContent"

export default function RoundedCardWithContent({ content, handleDisplay, state }) {

  const handleContent = () => {
    if(content.hasContent){
      handleDisplay(content.id, !state);
    }
  }

  return (
    <StepBox
      onClick={() => handleContent()}
      hasContent={content.hasContent}
      state={state}
    >
      <a
        href={content.link}
        target="_blank"
        rel="noreferrer"
      >

        <StepBoxTitle>
          <StepBoxNumberCtn>{content.id + 1}</StepBoxNumberCtn>
          <h4>
            {content.title}{" "}
            {content.subtitle &&
            <span>{content.subtitle}</span>
            }
          </h4>

          {content.hasContent &&
          <StepBoxArrow>
            <use href="/icons-sprite-map.svg#down-arrow-icon" />
          </StepBoxArrow>
          }
        </StepBoxTitle>
      </a>

      <StepBoxContent
        state={state}
      >
        {content.list && content.list.map((content, key) => {
          return (
            <StepBoxListCtn key={key}>
              <h5>
                {content.title} {" "} {content.subtitle &&
              <span>{content.subtitle}</span>
              }
              </h5>
              {content.ul &&
              <ul>
                {content.ul.map((li, key) => {
                  return <li key={key}>{li}</li>
                })}
              </ul>
              }
            </StepBoxListCtn>
          )
        })}

      </StepBoxContent>
    </StepBox>
  )
}

