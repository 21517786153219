import styled from "@emotion/styled"

export const StepBoxNumberCtn = styled.span`
  color: #000;
  font-weight: 400;
  min-width: 2.256rem;
  min-height: 2.256rem;
  display: flex;
  background-color: #fafafb;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 1.438rem;
`

export const StepBoxContent = styled.div`
  ${props => props.state ? `display : flex;` : `display:none;`}
  max-width: 41.46rem;
  margin: auto;
  flex-wrap: wrap;
`

export const StepBoxArrow = styled.svg`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  fill: #e2e3e6;
`


export const StepBox = styled.div`

  ${props => props.hasContent && `
    font-size: 0.98655785rem;
    cursor: pointer;
 `}

  ${props => props.state && `        
    padding-bottom: 1.7rem !important;
    max-width: 49.6879501rem !important;
    border-radius: 0.96rem !important;    
  `}

  box-sizing: border-box;
  display: block;
  margin: auto auto 2.4rem auto;
  max-width: 33.16rem;
  border: 0.24rem solid #2b3b99;
  align-items: center;
  padding: 4px 0 4px 0.96rem;
  border-radius: 4.8rem;
  transition: max-width 0.5s cubic-bezier(0.42, 0.0, 0.58, 1.0);

  a {
    text-decoration: none;
  }
`

export const StepBoxListCtn = styled.div`
  height: fit-content;

  &:last-of-type {
    margin: 0 !important;
  }

  &:nth-of-type(2n+1) {
    margin-right: 1.74rem;
  }

  width: 45%;

  h5 {
    padding-left: 0.719rem;
    border-left: 0.288rem solid #282a49;
    margin-bottom: 0.8rem !important;
    margin-top: 1.8rem;
    text-align: left;
  }

  span {
    font-weight: 300;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  li {
    color: #fafafb;
    font-weight: 300;
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
    margin: 0;
    text-align: start;
  }

  ul li:before {
    content: "•";
    font-size: 1rem;
    color: #fafafb;
    margin-right: 0.288rem;
  }
`

export const StepBoxTitle = styled.div`
  color: #fff;
  display: flex;
  align-items: center;

  h4 {
    font-weight: 700;
    text-transform: uppercase;
    margin-right: auto;

    span {
      font-weight: 300;
    }
  }
`
