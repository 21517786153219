import React from "react"
import ContactForm from "./ContactForm"
import ContactCTA from "./ContactCTA"
import Section from "../../Section/Section"

export default function ContactComponent({ DisplayContactForm, h2, h5, children, CTAmessage="BOOK A SESSION",subtitle}) {
  return (
    <>
      <Section
        Background={"transparent"}
      >

        {children &&
        <div style={{
          color: "#fff",
          textAlign: "center"
        }}>
          {children}
        </div>
        }
        <ContactCTA
          subtitle={subtitle}
          CTAmessage={CTAmessage}
          h2={h2}
          h5={h5}
        />
      </Section>
      {
        DisplayContactForm &&
        <Section
          Background={"transparent"}
        >
          <ContactForm />
        </Section>
      }
    </>
  )
}