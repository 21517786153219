import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import content from "../../../const/Content/StudyStepsContent.json"
import { Container } from "../../StyledComponents/Global"
import RoundedCardWithContent from "./Components/RoundedWithContent/RoundedCardWithContent"
import { countInArray } from "../../../tools/CommonsTools"

export default function StudySteps({ h2, h5, CTA, CTALink }) {

  const InitialState = []
  useEffect(() => {
    content.StudyStepsContent.map((mapped, key) => {
      InitialState[key] = false
    })
  }, [])

  const [displayStudyStep, SetDisplay] = useState(InitialState)

  const HandleDisplayedStep = (key, value) => {
    const displayed = [...displayStudyStep]

    if (countInArray(displayStudyStep, true) + 1 > 1) {
      displayed.fill(false)
      displayed[key] = value
      SetDisplay(displayed)
    } else {
      const displayed = [...displayStudyStep]
      displayed[key] = value
      SetDisplay(displayed)
    }
  }

  return (
    <Container>
      {h2 !== undefined &&
      <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
      }
      {h5 !== undefined &&
      <h5 dangerouslySetInnerHTML={{ __html: h5 }} />
      }

      {content.StudyStepsContent.map((mapped, key) => {
        return (
          <RoundedCardWithContent
            content={mapped}
            key={key}
            handleDisplay={HandleDisplayedStep}
            state={displayStudyStep[key]}
          />
        )
      })}
      {CTA !== undefined && <div>
        <Link className="cta-link" to={CTALink}>
          {CTA}
        </Link>
        <hr />
      </div>
      }

    </Container>
  )
}


