import React from "react"

export const SVGDelimiter = Object.freeze({
  Shape: {
    Line: 0,
    Rounded: 1,
    LineReverse:2,
  }
})

export default function DelimiterSVG({shape, Background, fillColor}) {
  if (shape === SVGDelimiter.Shape.Line) {
    return (
      <div
        style={{
          background : Background
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 125">
          <path fill={fillColor} d="M0,0,1920,125V0Z" />
        </svg>
      </div>
    )
  } else if(shape === SVGDelimiter.Shape.LineReverse){
    return (
      <div
        style={{
          background : Background,
          marginBottom: '-4px'
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 0 1920 125">
          <path fill={fillColor} d="M0,0,1920,125H0Z" />
        </svg>
      </div>
    )
  }else {
    return (
      <div
        style={{
          background: Background,
          marginRight : "-3px"
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 1920 125">
          <path
            fill={fillColor}
            d="M0,52.21,130.48,28.43c153.91-25.76,297-14.29,508,20.48C758.66,68.73,984.35,105.82,1280,121.75c212.29,9,367.56-2,528.55-16.48a7.25,7.25,0,0,1,1-.06,42,42,0,0,0,6.24-.33L1920,91.19V0H0Z"
          />
        </svg>
      </div>
    )
  }
}