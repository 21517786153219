import styled from "@emotion/styled"

export const Container = styled.div`
  height : fit-content;
`

export const ContentWrapper = styled.div`
  width: 80%;
`

